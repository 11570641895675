function AistechLogoSVG({ styles }) {
	return (
		<svg
			viewBox="0 0 579.67 84.93"
			className={styles}
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs />
			<g id="Capa_2" data-name="Capa 2">
				<g id="Capa_1-2" data-name="Capa 1">
					<path
						className="cls-1"
						d="M62.62 83.49l-3.46-11.57H30.41L27 83.49H0L29.94 1.43h30.65l29.94 82.06zM35.9 53.55h17.77l-8.59-29.22h-.48zM101.62 83.49V1.43H128v82.06zM207.18 6.38q10.14 6.38 10.38 18.91v1.43h-24.69v-.48a7.74 7.74 0 00-2.62-6q-2.62-2.38-8-2.39t-8 1.55c-1.87 1-2.8 2.31-2.8 3.82q0 3.22 3.82 4.77a73.41 73.41 0 0012.29 3.22 132.17 132.17 0 0116.28 4.23A27.74 27.74 0 01215 42.7q4.77 5 4.89 13.6 0 14.55-9.84 21.59t-26.3 7q-19.2 0-29.88-6.44t-10.72-22.74h24.93q0 6.21 3.22 8.29t10 2.09a27.28 27.28 0 008.31-1.09 4.29 4.29 0 003.28-4.41q0-3-3.64-4.47A72.57 72.57 0 00177.38 53a135.93 135.93 0 01-16.58-4.47 28 28 0 01-11.45-7.69q-4.89-5.37-4.89-14.55 0-13.47 10.44-19.86T181.3 0q15.7 0 25.88 6.38zM280.17 22.42v61.07h-26.36V22.42h-27v-21H307v21zM319.05 1.43h71v19.68h-44.64v11.33h38.17v18.85h-38.17v12.52h45.44v19.68h-71.8V1.43zM473.16 9.12q10.62 9.12 10.62 26.06h-25.17q0-7.16-3.76-11.33t-10.67-4.17q-8 0-11.75 5t-3.76 14v7.63q0 8.84 3.76 13.9t11.51 5.07q7.63 0 11.57-3.94t3.94-11.09h24.33q0 16.75-10.32 25.75t-29.16 9q-21 0-31.79-10.74t-10.79-31.8q0-21 10.79-31.73T444.29 0q18.25 0 28.87 9.12zM553.31 83.49V52.36h-29v31.13H498V1.43h26.36v29.94h29V1.43h26.36v82.06z"
					/>
				</g>
			</g>
		</svg>
	);
}

export default AistechLogoSVG;
