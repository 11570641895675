import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivateRoutes from "./router/PrivateRoutes";
import UberProvider from "./context/UberProvider";

import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import Flights from "./pages/Flights/Flights";
import PlanesLive from "./pages/PlanesLive/PlanesLive";
import SearchFlights from "./pages/SearchFlights/SearchFlights";
import Contact from "./pages/Contact/Contact";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

function App() {
	return (
		<UberProvider>
			<BrowserRouter>
				<Routes>
					<Route index element={<Login />} />
					<Route element={<ResetPassword />} path="/resetpassword" />
					<Route element={<PrivateRoutes />}>
						<Route element={<PlanesLive />} path="/planeslive" />
						<Route element={<Flights />} path="/flights/:pageId" />
						<Route element={<Dashboard />} path="/analytics" />
						<Route element={<SearchFlights />} path="/searchflights" />
						<Route element={<Contact />} path="/contact" />
					</Route>
					<Route element={<PageNotFound />} path="*" />
				</Routes>
			</BrowserRouter>
		</UberProvider>
	);
}

export default App;
