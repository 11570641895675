import { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { userDataContext } from "../../context/UserData/UserData";
import { authUser } from "../../utils/auth";

import InputGroup from "../../components/InputGroup/InputGroup";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";

import AistechLogoSVG from "../../assets/AistechLogoSVG/AistechLogoSVG";
import { useEffect } from "react";

function Login() {
	const navigate = useNavigate();
	const {
		userData: { email, password },
		setUserData,
	} = useContext(userDataContext);
	const [isLoading, setIsLoading] = useState(false);
	const [authError, setAuthError] = useState(null);
	const [loadingCheckBox, setLoadingCheckBox] = useState(true);
	const [checkBox, setCheckBox] = useState(false);
	const [emailDefaultValue, setEmailDefaultValue] = useState(false);
	const [passwordDefaultValue, setPasswordDefaultValue] = useState(false);

	useEffect(() => {
		const rememberEmail = localStorage.getItem("rememberEmail");

		if (rememberEmail) {
			setCheckBox(true);
			setEmailDefaultValue(rememberEmail);
			setUserData((prevState) => {
				return { ...prevState, email: rememberEmail };
			});
		}

		setLoadingCheckBox(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateField = (field, currentValue) => {
		if (field === "email") {
			setEmailDefaultValue(currentValue);
		} else if (field === "password") {
			setPasswordDefaultValue(currentValue);
		}
		setUserData((prevState) => {
			return { ...prevState, [field]: currentValue };
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		// TODO: Validate fields with React Hook Form
		try {
			setIsLoading(true);
			if (checkBox) {
				localStorage.setItem("rememberEmail", email);
			} else if (!checkBox) {
				localStorage.removeItem("rememberEmail");
			}

			const userIsLogged = await authUser(email, password);
			if (userIsLogged.error === false) {
				setUserData((prevState) => {
					return { ...prevState, password: "" };
				});
				navigate("/planeslive");
			} else if (userIsLogged.error === true) {
				setAuthError(userIsLogged.msg);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	return (
		<>
			<div className="w-full flex-center bg-primary-600 bg-opacity-50 overflow-y-auto">
				<div className="w-full min-h-screen flex-center">
					<form
						onSubmit={handleSubmit}
						className={`filter shadow-2xl rounded-lg p-6 flex flex-col w-360 lg:w-400 bg-white z-60 absolute items-center`}
					>
						<AistechLogoSVG styles="mt-8 w-4/6" />
						<h3 className="mt-6 mb-1 text-lg lg:text-xl font-bold">Welcome</h3>
						<p className={`text-gray-500 text-sm lg:text-base text-center`}>
							Please, login to access NEXUS platform
						</p>
						<div className="mt-5 mb-2 lg:mt-8 w-full">
							<InputGroup
								label="email"
								placeholder="user@aistechspace.com"
								capitalize={false}
								updateField={updateField}
								defaultValue={emailDefaultValue || ""}
							/>
							<InputGroup
								label="password"
								placeholder="***********"
								capitalize={false}
								updateField={updateField}
								inputType={"password"}
								defaultValue={passwordDefaultValue || ""}
							/>
							<label className="flex justify-end items-center gap-2 mb-2 text-sm">
								{" "}
								Remember your email
								<input
									type="checkbox"
									disabled={loadingCheckBox}
									value={checkBox}
									checked={checkBox}
									onChange={() => {
										setCheckBox(!checkBox);
									}}
								/>
							</label>
						</div>
						<div className="w-full">
							<Button type="primary" label="login" disabled={isLoading} onClick={handleSubmit} />
						</div>
						{authError ? (
							<p className="pt-3 text-red-600 font-bold text-center">{authError}</p>
						) : null}
						<NavLink className="pt-3" to="/resetpassword">
							Forgot your password?
						</NavLink>
					</form>
				</div>
			</div>
			<Footer absolute />
		</>
	);
}

export default Login;
