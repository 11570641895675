import { useState } from "react";

// React icons
import { FiLogOut } from "react-icons/fi";

function Avatar({ initials, image, handleClick }) {
	const [showLogOut, setShowLogOut] = useState(false);

	const avatarStyle = `flex-center rounded-full border-primary-600 lg:border-4 ${
		showLogOut
			? "bg-primary-600 text-white text-xl focus:bg-primary-700 focus:outline-none"
			: "bg-primary-500 text-primary-50 lg:text-xl font-bold font-display border-4"
	}`;

	const avatarSize = {
		height: "8vh",
		minHeight: "36px",
		maxHeight: "48px",
		aspectRatio: "1",
	};

	return (
		<div
			onMouseEnter={() => setShowLogOut(true)}
			onMouseLeave={() => setShowLogOut(false)}
		>
			<button className={avatarStyle} style={avatarSize} onClick={handleClick}>
				{/* Show user image. If the user doesn't have an image asigned show the initials.
        On hover: replace avatar by logOut button  */}

				{showLogOut ? (
					<FiLogOut />
				) : image ? (
					<div
						className="rounded-full h-full w-full bg-cover bg-center bg-no-repeat"
						style={{ backgroundImage: `url(${image})` }}
					></div>
				) : (
					initials
				)}
			</button>
		</div>
	);
}

export default Avatar;
