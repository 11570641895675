import { createContext, useState } from "react";

const initialState = {
	email: "",
	password: "",
};

export const userDataContext = createContext(initialState);

function UserDataProvider({ children }) {
	const [userData, setUserData] = useState(initialState);

	return (
		<userDataContext.Provider value={{ userData, setUserData }}>
			{children}
		</userDataContext.Provider>
	);
}

export default UserDataProvider;
