import axios from "axios";
import {
	signInWithEmailAndPassword,
	signOut,
	sendPasswordResetEmail,
} from "firebase/auth";
import { initFirebase } from "./firebase";

export const auth = initFirebase();

export const authUser = async (email, password) => {
	try {
		const userCredential = await signInWithEmailAndPassword(
			auth,
			email,
			password
		);
		const isTokenValid = await validateFirebaseToken(
			userCredential.user.accessToken
		);
		return isTokenValid;
	} catch (error) {
		const errorCode = error.code;
		const errorMessage = error.message;
		console.error(`Code: ${errorCode} - Message: ${errorMessage}`);
		return {
			error: true,
			msg: "Authentication failed. Please review the information and try again. If this error persist contact us. Error 002",
		};
	}
};

const validateFirebaseToken = (token) => {
	const headers = {
		headers: { Authorization: `Bearer ${token}` },
	};
	const baseURL = process.env.REACT_APP_DEV_URL || "localhost:4000";

	return axios
		.post(`${baseURL}/auth/login`, null, headers)
		.then((response) => response.data)
		.catch((error) => {
			console.error(error);
			return {
				error: true,
				msg: "There was an error with our service, please, contact us. Error 001",
			};
		});
};

export const logOut = async () => {
	try {
		const signingOut = await signOut(auth);
		return signingOut;
	} catch (error) {
		console.error(error);
	}
};

export const resetPasswordEmail = async (email) => {
	try {
		await sendPasswordResetEmail(auth, email);
	} catch (error) {
		const errorCode = error.code;
		const errorMessage = error.message;
		console.error(`Code: ${errorCode} - Message: ${errorMessage}`);
		return {
			error: true,
			msg: "We couldn't validate your email, please make sure you are using the right one. If this error persist please contact us. Error 003",
		};
	}
};
