import { flexRender } from "@tanstack/react-table";

function TableTemplate({ table }) {
	return (
		<table className="w-full">
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<th key={header.id} className="border-r-2 border-slate-600 last:border-r-0">
								{header.isPlaceholder
									? null
									: flexRender(header.column.columnDef.header, header.getContext())}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody className="shadow-xl">
				{table.getRowModel().rows.map((row, index) => {
					const isRowEven = index % 2 ? "even" : "odd";
					const stylesForEvenRows = "bg-primary-100 border-y-gray-300 border-y-2 text-center";
					const stylesForOddRows = "";

					return (
						<tr
							key={row.id}
							className={isRowEven === "even" ? stylesForEvenRows : stylesForOddRows}
						>
							{row.getVisibleCells().map((cell) => (
								<td className="px-6 py-3 text-center" key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					);
				})}
			</tbody>
			{/* <tfoot>
				{table.getFooterGroups().map((footerGroup) => (
					<tr key={footerGroup.id}>
						{footerGroup.headers.map((header) => (
							<th key={header.id}>
								{header.isPlaceholder
									? null
									: flexRender(header.column.columnDef.footer, header.getContext())}
							</th>
						))}
					</tr>
				))}
			</tfoot> */}
		</table>
	);
}

export default TableTemplate;
