import { useEffect, useState } from "react";

import Layout from "../../components/Layout/Layout";
import InputGroup from "../../components/InputGroup/InputGroup";
import Button from "../../components/Button/Button";
import SectionTab from "../../components/SectionTab/SectionTab";
import DisplayResults from "../../components/DisplayResults/DisplayResults";

import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";

import { columns } from "./queryColumns.js";

import mockedResponse from "./mockedResponse.json";

function SearchFlights() {
	const [searchFields, setSearchFields] = useState({});
	const [showForm, setShowForm] = useState(true);
	const [queryData, setQueryData] = useState([]);
	const [displayQueryResponse, setDisplayQueryResponse] = useState(false);
	const [currentSection, setCurrentSection] = useState(null);
	const [displayWarning, setDisplayWarning] = useState(true);
	const [wrapJSONresponse, setWrapJSONresponse] = useState(false);

	useEffect(() => {
		setCurrentSection(sectionsToDisplay[0]);
	}, []);

	const updateField = (field, currentValue) => {
		setSearchFields((prevState) => {
			return { ...prevState, [field]: currentValue };
		});
	};

	const handleSearch = (e) => {
		e.preventDefault();
		console.log(searchFields); // CHECK FIELDS IN CONSOLE
		handleQuery();
		setShowForm(false);
		setDisplayQueryResponse(true);
	};

	const handleQuery = async () => {
		setQueryData(mockedResponse);
	};

	const sectionsToDisplay = ["map", "table", "JSON"];

	return (
		<Layout
			section={"searchFlights"}
			title={"Search flights"}
			description={"Look for flights in the system"}
		>
			{displayWarning && (
				<div className="flex justify-between items-center w-full py-2 mb-2 bg-amber-200">
					<div className="w-3 h-3 rounded-lg ml-4 bg-amber-500 animate-ping"></div>
					<div>
						This form is just a demo, and the response will always be the same no matter the query.
						Please, see this form just as an example
					</div>
					<div className="cursor-pointer mr-4">
						<AiOutlineClose
							onClick={() => {
								setDisplayWarning((prevState) => !prevState);
							}}
						/>
					</div>
				</div>
			)}
			<div className="flex justify-end">
				<button
					className="flex items-center"
					onClick={() => {
						setShowForm((prevState) => !prevState);
					}}
				>
					{showForm ? "Hide" : "Display"} search form
					{showForm ? <MdOutlineKeyboardArrowDown /> : <MdOutlineKeyboardArrowUp />}
				</button>
			</div>
			{showForm && (
				<form className="grid md:grid-cols-2 md:gap-5 mb-6" onSubmit={handleSearch}>
					<div className="flex gap-4">
						<InputGroup
							label="ICAO Aircraft Hex ID"
							placeholder="0.5"
							capitalize={false}
							updateField={updateField}
							required
						/>
						<InputGroup
							label="Call Sign"
							placeholder="100"
							capitalize={false}
							updateField={updateField}
							required
						/>
					</div>
					<InputGroup
						label="Data source"
						placeholder="0.5"
						capitalize={false}
						updateField={updateField}
						required
					/>
					<InputGroup
						label="Minimum Latitude"
						placeholder="0.5"
						capitalize={false}
						updateField={updateField}
						required
					/>
					<InputGroup
						label="Maximum Latitude"
						placeholder="100"
						capitalize={false}
						updateField={updateField}
						required
					/>
					<InputGroup
						label="Minimum Longitude"
						placeholder="0.5"
						capitalize={false}
						updateField={updateField}
						required
					/>
					<InputGroup
						label="Maximum Longitude"
						placeholder="100"
						capitalize={false}
						updateField={updateField}
						required
					/>
					<InputGroup
						label="Minimum Altitude"
						placeholder="0.5"
						capitalize={false}
						updateField={updateField}
						required
					/>
					<InputGroup
						label="Maximum Altitude"
						placeholder="100"
						capitalize={false}
						updateField={updateField}
						required
					/>
					<InputGroup
						label="Minimum Date"
						capitalize={false}
						updateField={updateField}
						required
						inputType="date"
					/>
					<InputGroup
						label="Maximum Date"
						capitalize={false}
						updateField={updateField}
						required
						inputType="date"
					/>
					<InputGroup
						label="Minimum Hour"
						capitalize={false}
						updateField={updateField}
						required
						inputType="time"
					/>
					<InputGroup
						label="Maximum Hour"
						capitalize={false}
						updateField={updateField}
						required
						inputType="time"
					/>
					<Button
						className="mb-4 md:mb-0"
						type="ghost"
						label="Display flights on map"
						onClick={handleSearch}
					/>
					<Button type="primary" label="Search flights" onClick={handleSearch} />
				</form>
			)}
			{displayQueryResponse && (
				<div>
					<div className="flex-center w-full mb-12">
						<div className={`grid grid-cols-3 gap-12 lg:gap-16 mt-5`}>
							{sectionsToDisplay.map((sectionToDisplay) => {
								return (
									<SectionTab
										key={sectionToDisplay}
										section={sectionToDisplay}
										currentSection={currentSection}
										label={`Show as ${sectionToDisplay}`}
										handleClick={(e, section) => {
											setCurrentSection(section);
										}}
									/>
								);
							})}
						</div>
					</div>
					{currentSection !== null && (
						<>
							{currentSection === "JSON" && (
								<Button
									label={`${wrapJSONresponse ? "Unwrap" : "Wrap"} JSON response`}
									type="borderless"
									onClick={() => {
										setWrapJSONresponse((prevState) => !prevState);
									}}
								/>
							)}
							<DisplayResults
								columns={columns}
								section={currentSection}
								data={queryData}
								wrapContent={wrapJSONresponse}
							/>
						</>
					)}
				</div>
			)}
		</Layout>
	);
}

export default SearchFlights;
