import { NavLink } from "react-router-dom";

import Footer from "../../components/Footer/Footer";

import AistechLogoSVG from "../../assets/AistechLogoSVG/AistechLogoSVG";

function Login() {
	return (
		<>
			<div className="w-full flex-center bg-primary-600 bg-opacity-50 overflow-y-auto">
				<div className="w-full min-h-screen flex-center">
					<div
						className={`filter shadow-2xl rounded-lg p-6 flex flex-col w-360 lg:w-400 bg-white z-60 absolute items-center`}
					>
						<AistechLogoSVG styles="mt-8 w-4/6" />
						<h3 className="mt-6 mb-1 text-lg lg:text-xl font-bold">404 - Page not found</h3>
						<p className={`text-sm lg:text-base text-center`}>
							The page you are looking for is not currently available. Please, if this error persist
							contact us.
						</p>
						<NavLink className="pt-3" to="/">
							Click here to go back to login page
						</NavLink>
					</div>
				</div>
			</div>
			<Footer absolute />
		</>
	);
}

export default Login;
