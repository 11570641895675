import { useRef, useState } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";

import Layout from "../../components/Layout/Layout";
import InputGroup from "../../components/InputGroup/InputGroup";
import Button from "../../components/Button/Button";
import FlashModal from "../../components/FlashModal/FlashModal";

function Contact() {
	const [contactFields, setContactFields] = useState({});
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState("");
	const [alertMessage, setAlertMessage] = useState("");
	const formRef = useRef();

	const auth = getAuth();

	const updateField = (field, currentValue) => {
		setContactFields((prevState) => {
			// TODO: Refactor to single custom hook
			if (currentValue === "") {
				// AVOID USE OBJECT BY REFERENCE:
				const prevStateCopy = structuredClone(prevState);
				delete prevStateCopy[field];
				return prevStateCopy;
			}
			return { ...prevState, [field]: currentValue };
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const token = await auth.currentUser.getIdToken();
		const headers = {
			headers: { Authorization: `Bearer ${token}` },
		};
		const baseURL = process.env.REACT_APP_DEV_URL || "localhost:4000";
		const request = await axios.post(
			`${baseURL}/contact`,
			contactFields,
			headers
		);
		if (request.data.error === false) {
			setAlertStatus("success");
			setAlertMessage(request.data.msg);
			formRef.current.reset();
		}
		setShowAlert(true);
	};

	return (
		<Layout
			section={"contact"}
			title={"Contact with us"}
			description={"Need support? Please, send us a message"}
		>
			<form ref={formRef} onSubmit={handleSubmit}>
				<div className="flex gap-0 md:gap-5 flex-wrap md:flex-nowrap">
					<InputGroup
						label="email"
						placeholder="email@yourorg.com"
						capitalize={false}
						updateField={updateField}
						required
					/>
					<InputGroup
						label="subject"
						placeholder="I have a problem with the platform"
						capitalize={false}
						updateField={updateField}
						required
					/>
				</div>
				<InputGroup
					label="message"
					placeholder="Tell us what you need"
					capitalize={false}
					updateField={updateField}
					textArea
					required
				/>
				<Button type="primary" label="Send" onClick={handleSubmit} />
			</form>
			{showAlert && (
				<FlashModal
					status={alertStatus}
					message={alertMessage}
					setShowFlashModal={setShowAlert}
				/>
			)}
		</Layout>
	);
}

export default Contact;
