const AistechLogo =
	"M62.62,83.49,59.16,71.92H30.41L27,83.49H0L29.94,1.43H60.59L90.53,83.49ZM35.9,53.55H53.67L45.08,24.33H44.6Z M101.62,83.49V1.43H128V83.49Z M207.18,6.38q10.14,6.38,10.38,18.91v1.43H192.87v-.48a7.74,7.74,0,0,0-2.62-6q-2.62-2.38-8-2.39t-8,1.55c-1.87,1-2.8,2.31-2.8,3.82q0,3.22,3.82,4.77a73.41,73.41,0,0,0,12.29,3.22,132.17,132.17,0,0,1,16.28,4.23A27.74,27.74,0,0,1,215,42.7q4.77,5,4.89,13.6,0,14.55-9.84,21.59t-26.3,7q-19.2,0-29.88-6.44T143.15,55.71h24.93q0,6.21,3.22,8.29t10,2.09A27.28,27.28,0,0,0,189.61,65a4.29,4.29,0,0,0,3.28-4.41q0-3-3.64-4.47A72.57,72.57,0,0,0,177.38,53a135.93,135.93,0,0,1-16.58-4.47,28,28,0,0,1-11.45-7.69q-4.89-5.37-4.89-14.55,0-13.47,10.44-19.86T181.3,0Q197,0,207.18,6.38Z M280.17,22.42V83.49H253.81V22.42h-27v-21H307v21Z M319.05,1.43h71V21.11H345.41V32.44h38.17V51.29H345.41V63.81h45.44V83.49h-71.8V1.43Z M473.16,9.12q10.62,9.12,10.62,26.06H458.61q0-7.16-3.76-11.33t-10.67-4.17q-8,0-11.75,5t-3.76,14v7.63q0,8.84,3.76,13.9t11.51,5.07q7.63,0,11.57-3.94t3.94-11.09h24.33Q483.78,67,473.46,76t-29.16,9q-21,0-31.79-10.74T401.72,42.46q0-21,10.79-31.73T444.29,0Q462.54,0,473.16,9.12Z M553.31,83.49V52.36h-29V83.49H498V1.43h26.36V31.37h29V1.43h26.36V83.49Z";

function Footer({ absolute }) {
	// mimic avatar height from navbar
	// Carlos: inherited by component library
	const size = {
		height: "calc(1rem + 8vh)",
		minHeight: "calc(1rem + 36px)",
		maxHeight: "calc(1rem + 48px)",
	};

	return (
		<div
			className={`w-full flex items-center justify-between bg-gray-400 text-gray-100 h-11 px-5 lg:px-10 py-3 text-xs lg:text-sm bottom-0 flex-none ${
				absolute ? "absolute" : null
			}`}
			style={size}
		>
			<svg height="12" viewBox="0 0 579.67 84.93" fill="white">
				<path d={AistechLogo}></path>
			</svg>
			<p>©{new Date().getFullYear()} Aistech Space S.L. All rights reserved</p>
		</div>
	);
}

export default Footer;
