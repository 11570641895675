import { NavLink, useParams } from "react-router-dom";

function PaginationButton({ label, currentLocationWithoutPage }) {
	const { pageId } = useParams();

	const active = pageId === label;

	const activeStyle = active
		? "bg-primary-600 text-primary-25"
		: "text-primary-600 hover:bg-primary-500 hover:text-primary-25";

	return (
		<NavLink
			to={`${currentLocationWithoutPage}${label}`}
			className={`${activeStyle} flex justify-center items-center lg:w-10 lg:h-10 w-8 h-8 rounded-full focus:bg-primary-600 focus:text-primary-25 focus:outline-none text-sm mr-2`}
		>
			{label}
		</NavLink>
	);
}

export default PaginationButton;
