import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { userDataContext } from "../../context/UserData/UserData";
import { resetPasswordEmail } from "../../utils/auth";

import InputGroup from "../../components/InputGroup/InputGroup";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FlashModal from "../../components/FlashModal/FlashModal";

import AistechLogoSVG from "../../assets/AistechLogoSVG/AistechLogoSVG";

function ResetPassword() {
	const {
		userData: { email },
	} = useContext(userDataContext);
	const [isLoading, setIsLoading] = useState(false);
	const [emailError, setEmailError] = useState(null);
	const [emailInput, setEmailInput] = useState(email);
	const [showFlashModal, setShowFlashModal] = useState(false);
	const navigate = useNavigate();

	const updateField = (field, currentValue) => {
		setEmailInput((prevState) => {
			return { ...prevState, [field]: currentValue };
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setEmailError(false);
		// TODO: Validate fields with React Hook Form
		try {
			setIsLoading(true);
			const emailIsSended = await resetPasswordEmail(emailInput.email);
			if (emailIsSended === undefined) {
				setShowFlashModal(true);
				setIsLoading(false);
			} else if (emailIsSended.error === true) {
				setEmailError(emailIsSended.msg);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	const handleGoBack = (e) => {
		e.preventDefault();
		navigate("/");
	};

	return (
		<>
			<div className="w-full flex-center bg-primary-600 bg-opacity-50 overflow-y-auto">
				<div className="w-full min-h-screen flex-center">
					<form
						onSubmit={handleSubmit}
						className={`filter shadow-2xl rounded-lg p-6 flex flex-col w-360 lg:w-400 bg-white z-60 absolute items-center`}
					>
						<AistechLogoSVG styles="mt-8 w-4/6" />
						<h3 className="mt-6 mb-1 text-lg lg:text-xl font-bold">
							Reset password
						</h3>
						<p className={`text-gray-500 text-sm lg:text-base text-center`}>
							Please, type your email to reset your password
						</p>
						<div className="mt-5 mb-2 lg:mt-8 w-full">
							<InputGroup
								label="email"
								placeholder="user@aistechspace.com"
								capitalize={false}
								value={email}
								updateField={updateField}
							/>
						</div>
						<div className="flex  gap-3 w-full">
							<Button
								type="borderless"
								label="Back to login"
								disabled={isLoading}
								onClick={handleGoBack}
							/>
							<Button
								type="primary"
								label="Reset password"
								disabled={isLoading}
								onClick={handleSubmit}
							/>
						</div>
						{emailError ? (
							<p className="pt-3 text-red-600 font-bold text-center">
								{emailError}
							</p>
						) : null}
					</form>
				</div>
				{showFlashModal && (
					<FlashModal
						status={"success"}
						message={"Email sended, please check your inbox and spam"}
						setShowFlashModal={setShowFlashModal}
					/>
				)}
			</div>
			<Footer absolute />
		</>
	);
}

export default ResetPassword;
