function StatsCard({ value = "1000", title = "stats", primary = false }) {
	const cardStyle = `${
		primary ? "bg-primary-700" : "bg-secondary-600"
	} rounded-xl p-4 text-white font-display font-bold uppercase`;

	return (
		<div className={cardStyle}>
			<h4 className="text-4xl lg:text-6xl text-center mt-12 lg:mt-16 mb-6 lg:mb-10">
				{value}
			</h4>
			<i className="fas fa-plane mr-3"></i>
			<h4 className="text-white text-opacity-80 text-sm lg:text-base">
				{title}
			</h4>
		</div>
	);
}

export default StatsCard;
