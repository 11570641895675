function TopBar({ title, description }) {
	return (
		<div className="w-full flex justify-between p-5 lg:p-10 font-body min-h-100 lg:min-h-160">
			<div className="border-1 border-black">
				<h1 className="text-primary-800 font-bold text-3xl lg:text-5xl">{title}</h1>
				{description && <p className="text-gray-600 lg:text-xl">{description}</p>}
			</div>
		</div>
	);
}

export default TopBar;
