import { createColumnHelper } from "@tanstack/react-table";

import useBrowserLanguage from "../../hooks/useBrowserLanguage.js";

const columnHelper = createColumnHelper();

const language = useBrowserLanguage() ?? "en-US";

export const columns = [
	columnHelper.accessor("icao", {
		header: () => "ICAO",
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor("latitude", {
		header: () => "LATITUDE",
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor("longitude", {
		header: () => "LONGITUDE",
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor("altitude", {
		header: () => "ALTITUDE",
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor("epoch", {
		header: () => "TIME",
		cell: (info) => {
			const date = new Date(0).setUTCSeconds(info.getValue()); // The 0 there is the key, which sets the date to the epoch
			return new Intl.DateTimeFormat(language, { timeStyle: "long" }).format(date);
		},
	}),
	columnHelper.accessor("true_track", {
		header: () => "ROTATION",
		cell: (info) => `${info.getValue()}°`,
	}),
];
