function ModalBackdrop() {
	return (
		<div
			className="fixed inset-0 bg-primary-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
			id="my-modal"
		></div>
	);
}

export default ModalBackdrop;
