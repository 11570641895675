import { Marker } from "react-map-gl";

// import MapPlanePopup from "../MapPlanePopup/MapPlanePopup.jsx";

import planeSVG from "../../assets/plane.svg";
// import PlaneSVG from "../../assets/PlaneSVG/PlaneSVG";

function MapPlaneMarker({ plane, setPopupInfo }) {
	return (
		<Marker
			longitude={plane.longitude}
			latitude={plane.latitude}
			anchor="bottom"
			rotation={plane.true_track}
			onClick={(e) => {
				// If we let the click event propagates to the map, it will immediately close the popup with `closeOnClick: true`
				e.originalEvent.stopPropagation();
				setPopupInfo(plane);
			}}
		>
			<img className="cursor-pointer" src={planeSVG} />
		</Marker>
	);
}

export default MapPlaneMarker;
