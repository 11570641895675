import { MdClose } from "react-icons/md";

import Button from "../Button/Button";
import ModalBackdrop from "../ModalBackdrop/ModalBackdrop";

function SidePanel({
	title = "New element",
	setDisplayAside,
	showAcceptButton = true,
	showCancelButton = true,
	labelAcceptButton = "Apply",
	labelCancelButton = "Cancel",
	children,
}) {
	return (
		<>
			<div
				id="side-panel"
				className="w-1/3 h-screen fixed right-0 top-0 bg-white shadow-xl p-5 lg:p-10 z-65 overflow-y-hidden"
				style={{ minWidth: "280px" }}
			>
				<button
					onClick={() => setDisplayAside(false)}
					className="text-gray-400 transform translate-y-1 hover:text-gray-700 fixed right-5 lg:right-10 top-5 lg:top-10"
				>
					<MdClose />
				</button>

				{/* Content */}
				<div id="side-panel--content" className="h-full w-full flex flex-col justify-between">
					<h2 className="font-bold font-display text-xl lg:text-2xl text-primary-800 mb-6 flex-grow-0">
						{title}
					</h2>

					{/* scrollable content */}
					<div id="side-panel--scrollable-section" className="overflow-auto flex-grow">
						{children}
					</div>

					{/* Action buttons */}
					<div
						id="side-panel--action buttons"
						className="grid grid-cols-2 gap-3 w-full mt-6 flex-grow-0"
					>
						{showCancelButton && (
							<Button
								type="ghost"
								label={labelCancelButton}
								onClick={() => setDisplayAside(false)}
							/>
						)}
						{showAcceptButton && <Button type="primary" label={labelAcceptButton} />}
					</div>
				</div>
			</div>

			<ModalBackdrop />
		</>
	);
}

export default SidePanel;
