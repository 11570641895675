import { useLocation, useNavigate, useParams } from "react-router-dom";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";

import PaginationButton from "../PaginationButton/PaginationButton";

function Pagination({ pages = 6, maxPages = 6 }) {
	const navigate = useNavigate();
	const { pageId } = useParams();
	const pageIdNumber = Number(pageId);

	const location = useLocation();

	// ? TODO: REFACTOR TO CUSTOM HOOK
	const currentLocationWithoutPage = location.pathname.slice(
		0,
		location.pathname.lastIndexOf("/") + 1
	);

	const pagesArray = [...Array(pages).keys()].map((i) => i + 1);
	const pags = pagesArray.map((num) => {
		return (
			<PaginationButton
				key={num}
				label={num.toString()}
				active={num === 1}
				currentLocationWithoutPage={currentLocationWithoutPage}
			/>
		);
	});

	const style =
		"flex items-center ju text-gray-500 hover:text-black focus:outline-none text-sm lg:text-base disabled:cursor-not-allowed disabled:text-gray-300";

	const isFirstPage = pageIdNumber === 1;
	const isLastPage = pageIdNumber === maxPages;

	return (
		<div className="flex justify-between w-full mt-8">
			<button
				disabled={isFirstPage}
				className={`${style}`}
				onClick={() => {
					navigate(`${currentLocationWithoutPage}${pageIdNumber - 1}`);
				}}
			>
				<HiArrowLeft className="mr-2" /> Previous
			</button>

			{/* // ! CARE, IF EXCESIVE NUMBER OF PAGES THIS WILL DISPLAY THEM ALL */}
			<div className="flex">{pags}</div>

			<button
				disabled={isLastPage}
				className={`${style} justify-end`}
				onClick={() => {
					navigate(`${currentLocationWithoutPage}${pageIdNumber + 1}`);
				}}
			>
				Next <HiArrowRight className="ml-2" />
			</button>
		</div>
	);
}

export default Pagination;
