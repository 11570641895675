import axios from "axios";
import { useEffect, useState } from "react";
import useBrowserLanguage from "../../hooks/useBrowserLanguage.js";

import Map, { NavigationControl, FullscreenControl, GeolocateControl } from "react-map-gl";

import Layout from "../../components/Layout/Layout";
import MapPlaneMarker from "../../components/MapPlaneMarker/MapPlaneMarker";
import MapPlanePopup from "../../components/MapPlanePopup/MapPlanePopup";
import SidePanel from "../../components/SidePanel/SidePanel";

import { getAirCraftImage } from "../../utils/getAircraftImage.js";

import "mapbox-gl/dist/mapbox-gl.css";

import defaultPlane from "../../assets/defaultPlane.jpg";

const asideSubheadingStyle = "font-bold text-primary-75 bg-primary-700";

function PlanesLive() {
	const [planes, setPlanes] = useState([]);
	const [popupInfo, setPopupInfo] = useState(null);
	const [aircraftImg, setAircraftImg] = useState(null);
	const [displayAside, setDisplayAside] = useState(false);
	const language = useBrowserLanguage() ?? "en-US";

	useEffect(() => {
		updatePlanes();

		const intervalId = setInterval(() => {
			updatePlanes();
		}, 5000);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		const updateAircraftImg = async () => {
			if (!popupInfo) return;
			setAircraftImg(null);

			// TODO: REFACTOR USING TANSTACK QUERY AND USE ISLOADING TO AVOID FLICKING
			const aircraftImg = await getAirCraftImage(popupInfo.icao);
			setAircraftImg(aircraftImg);
		};

		updateAircraftImg();
	}, [popupInfo]);

	const updatePlanes = async () => {
		// TODO: AVOID HARDCODING THIS URL
		const updatedPlanes = await axios.get("http://34.79.150.95/planes/");
		setPlanes(updatedPlanes.data.data);
	};

	const getCurrentUserTime = () => {
		if (!popupInfo.epoch) return;

		const date = new Date(0).setUTCSeconds(popupInfo?.epoch); // The 0 there is the key, which sets the date to the epoch
		const userCurrentTime = new Intl.DateTimeFormat(language, { timeStyle: "long" }).format(date);
		return userCurrentTime;
	};

	const initialPosition = {
		latitude: 41.38607310577369,
		longitude: 2.1788423889462885,
	};

	return (
		<Layout section={"planeslive"} contentMargin={false} displayTopBar={false}>
			<Map
				initialViewState={{
					longitude: initialPosition.longitude,
					latitude: initialPosition.latitude,
					zoom: 7,
				}}
				style={{ height: "100%" }}
				mapStyle="mapbox://styles/mapbox/dark-v10"
				mapboxAccessToken={
					"pk.eyJ1IjoiY3ZlbGlsbGFmIiwiYSI6ImNsOTJtMW0zdzEyc3gzdXFtdDkyeTVrdHAifQ.5vLrKys46D6jpBvN7PLVrg"
				}
			>
				<GeolocateControl position="top-right" />
				<FullscreenControl position="top-right" />
				<NavigationControl position="top-right" />

				{planes &&
					planes?.map((plane) => (
						<MapPlaneMarker key={plane.icao} plane={plane} setPopupInfo={setPopupInfo} />
					))}

				{popupInfo && (
					<MapPlanePopup
						popupInfo={popupInfo}
						setPopupInfo={setPopupInfo}
						setDisplayAside={setDisplayAside}
					/>
				)}
			</Map>

			{displayAside && (
				<SidePanel
					title="Positional information detail"
					setDisplayAside={setDisplayAside}
					showAcceptButton={false}
					labelCancelButton="Close"
				>
					<div>
						<img
							src={aircraftImg ?? defaultPlane}
							alt="Image of the plane displayed"
							className="m-auto"
						/>
						{/* src={defaultPlane} */}
						<div className="mt-4">
							<h2 className="text-xl font-bold text-center mb-4">
								Flight with ICAO: <span>{popupInfo.icao}</span>
							</h2>
							<div className="grid lg:grid-cols-2 gap-5">
								<div className="text-center">
									<h3 className={asideSubheadingStyle}>Latitude</h3>
									<p>{popupInfo.latitude}</p>
								</div>
								<div className="text-center">
									<h3 className={asideSubheadingStyle}>Longitude</h3>
									<p>{popupInfo.longitude}</p>
								</div>
								<div className="text-center">
									<h3 className={asideSubheadingStyle}>Altitude (ft)</h3>
									<p>{popupInfo.altitude}</p>
								</div>
								<div className="text-center">
									<h3 className={asideSubheadingStyle}>Flight ID</h3>
									<p>{popupInfo.callsign}</p>
								</div>
								<div className="text-center">
									<h3 className={asideSubheadingStyle}>Time</h3>
									<p>{getCurrentUserTime()}</p>
								</div>
								<div className="text-center">
									<h3 className={asideSubheadingStyle}>Speed (kn)</h3>
									<p>{popupInfo.speed}</p>
								</div>
							</div>
						</div>
					</div>
				</SidePanel>
			)}
		</Layout>
	);
}

export default PlanesLive;
