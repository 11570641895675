import axios from "axios";
import { useState, useEffect, useContext } from "react";
import {
	LineChart,
	Line,
	Tooltip,
	XAxis,
	CartesianGrid,
	YAxis,
	ResponsiveContainer,
} from "recharts";

import useBrowserLanguage from "../../hooks/useBrowserLanguage";

import { userDataContext } from "../../context/UserData/UserData";

import Layout from "../../components/Layout/Layout";
import Greet from "../../utils/Greet";
import StatsCard from "../../components/StatsCard/StatsCard.jsx";

// import ACISlogo from "../../assets/Aistech-lettersOnly-black.svg";

function Dashboard() {
	const language = useBrowserLanguage() ?? "en-US";
	const [chartData, setChartData] = useState([]);
	const {
		userData: { email },
	} = useContext(userDataContext);

	const secondsToReload = 10;

	const userName = email.split("@");
	const greetTo = Greet(userName[0]);

	useEffect(() => {
		updatePlanes();

		const intervalId = setInterval(() => {
			updatePlanes();
		}, secondsToReload * 1000);

		return () => clearInterval(intervalId);
	}, []);

	const updatePlanes = async () => {
		// TODO: AVOID HARDCODING THIS URL
		const updatedPlanes = await axios.get("http://34.79.150.95/health/stats");
		const numberOfPlanes = updatedPlanes.data.message.cache_size;
		const terminalsAlive = updatedPlanes.data.message.terminals_alive;
		const timeOfCall = new Date(updatedPlanes.data.message.time);
		const newChartEntry = {
			numberOfPlanes,
			terminalsAlive,
			timeOfCall: new Intl.DateTimeFormat(language, {
				hour: "numeric",
				minute: "numeric",
				second: "numeric",
			}).format(timeOfCall),
		};

		setChartData((prevState) => [...prevState, newChartEntry]);
	};

	return (
		<Layout section={"dashboard"} title={greetTo} description={`Welcome to Nexus Aviation System`}>
			<div className="grid md:grid-cols-2 gap-4">
				<StatsCard
					value={chartData.at(-1)?.numberOfPlanes ?? 0}
					title={`TOTAL PLANES IN OUR NET (UPDATED EVERY ${secondsToReload} SECONDS)`}
					primary={true}
				/>
				<StatsCard
					value={chartData.at(-1)?.terminalsAlive ?? 0}
					title={`TOTAL ONLINE TERMINALS IN OUR NET (UPDATED EVERY ${secondsToReload} SECONDS)`}
					primary={false}
				/>
			</div>

			<div className="mt-10">
				<h2 className="text-primary-800 font-bold text-center mb-3">
					CURRENT LIVE PLANES IN OUR NET (UPDATED EVERY {secondsToReload} SECONDS)
				</h2>
				<ResponsiveContainer width="100%" height={300} className="-ml-5">
					<LineChart data={chartData}>
						<Line
							type="monotone"
							dataKey="numberOfPlanes"
							name="Amount of planes"
							stroke="#203B75"
							strokeWidth={3}
						/>
						<CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
						<XAxis dataKey="timeOfCall" />
						<YAxis />
						<Tooltip />
					</LineChart>
				</ResponsiveContainer>
			</div>
		</Layout>
	);
}

export default Dashboard;
