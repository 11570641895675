import { Popup } from "react-map-gl";
import Button from "../Button/Button";

function MapPlanePopup({ popupInfo, setPopupInfo, setDisplayAside, showAside = true }) {
	return (
		<Popup
			anchor="top"
			closeButton={false}
			longitude={Number(popupInfo.longitude)}
			latitude={Number(popupInfo.latitude)}
			onClose={() => setPopupInfo(null)}
		>
			<div className="font-body m-2 max-w-xl">
				<h2 className="text-lg font-bold text-center mb-1">
					Flight with ICAO: <span className="font-normal">{popupInfo.icao}</span>
				</h2>
				<div className="grid grid-cols-3 gap-x-10 text-base">
					<div className="flex flex-col justify-center items-center text-center">
						<p className="font-bold">Latitude</p>
						<span>{popupInfo.latitude}°</span>
					</div>
					<div className="flex flex-col justify-center items-center text-center">
						<p className="font-bold">Longitude</p>
						<span>{popupInfo.longitude}°</span>
					</div>
					<div className="flex flex-col justify-center items-center text-center">
						<p className="font-bold">Altitude</p>
						<span>{popupInfo.altitude}</span>
					</div>
				</div>
				{showAside && (
					<Button
						type="primary"
						label="Detail"
						className={"mt-3"}
						onClick={() => {
							setDisplayAside(true);
						}}
					/>
				)}
			</div>
		</Popup>
	);
}

export default MapPlanePopup;
