import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

import Layout from "../../components/Layout/Layout";
import TableTemplate from "../../components/TableTemplate/TableTemplate";
import Pagination from "../../components/Pagination/Pagination";

import { columns } from "./FlightsColumns.js";

function Flights() {
	const [data, setData] = useState([]);
	const [amountOfPages, setAmountOfPages] = useState(0);
	const { pageId } = useParams();

	const amountOfFlightsPerPage = 15;

	useEffect(() => {
		const getAmountOfPages = async () => {
			const allFlights = await axios.get("http://34.79.150.95/planes/");
			const amountFloat = allFlights.data.data.length / amountOfFlightsPerPage;
			const amountRound = Math.ceil(amountFloat);
			setAmountOfPages(amountRound);
		};

		getAmountOfPages();
	}, []);

	useEffect(() => {
		getFlights();

		const intervalId = setInterval(() => {
			getFlights();
		}, 5000);

		return () => clearInterval(intervalId);
	}, [pageId]);

	const getFlights = async () => {
		const allFlights = await axios.get("http://34.79.150.95/planes/");

		const flightsForCurrentPage = allFlights.data.data.slice(
			(pageId - 1) * amountOfFlightsPerPage,
			pageId * amountOfFlightsPerPage
		);
		setData([...flightsForCurrentPage]);
	};

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<Layout
			section={"flights/1"}
			title={"Positional data"}
			description={"Visualize live positional data"}
		>
			<TableTemplate table={table} />
			<Pagination pages={amountOfPages} maxPages={amountOfPages} />
		</Layout>
	);
}

export default Flights;
