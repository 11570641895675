import { createColumnHelper } from "@tanstack/react-table";

import useBrowserLanguage from "../../hooks/useBrowserLanguage.js";

// import Check from "../../assets/icons/Check/Check";
// import Cross from "../../assets/icons/Cross/Cross";

const columnHelper = createColumnHelper();

const language = useBrowserLanguage() ?? "en-US";

export const columns = [
	columnHelper.accessor("icao", {
		header: () => "ICAO",
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor("latitude", {
		header: () => "LATITUDE",
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor("longitude", {
		header: () => "LONGITUDE",
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor("altitude", {
		header: () => "ALTITUDE",
		cell: (info) => info.getValue(),
	}),
	columnHelper.accessor("epoch", {
		header: () => "TIME",
		cell: (info) => {
			const date = new Date(0).setUTCSeconds(info.getValue()); // The 0 there is the key, which sets the date to the epoch
			return new Intl.DateTimeFormat(language, { timeStyle: "long" }).format(date);
		},
	}),
	columnHelper.accessor("true_track", {
		header: () => "ROTATION",
		cell: (info) => `${info.getValue()}°`,
	}),
];

// ? THIS COLUMNS DEFINITION IS USED FOR EXAMPLE DATA FROM MOCKDATA.JSON, ERASE WHEN NOT USABLE ANYMORE
// export const columns = [
// 	columnHelper.accessor("CALLSIGN", {
// 		header: (info) => info.header.id,
// 		cell: (info) => info.getValue(),
// 		footer: (info) => info.column.id,
// 	}),
// 	columnHelper.accessor("ORIGIN", {
// 		header: (info) => info.header.id,
// 		cell: (info) => info.getValue(),
// 		footer: (info) => info.column.id,
// 	}),
// 	columnHelper.accessor("DESTINATION", {
// 		header: (info) => info.header.id,
// 		cell: (info) => info.getValue(),
// 		footer: (info) => info.column.id,
// 	}),
// 	columnHelper.accessor("OPERATOR", {
// 		header: (info) => info.header.id,
// 		cell: (info) => info.getValue(),
// 		footer: (info) => info.column.id,
// 	}),
// 	columnHelper.accessor("DEPARTURE", {
// 		header: (info) => info.header.id,
// 		cell: (info) => info.getValue(),
// 		footer: (info) => info.column.id,
// 	}),
// 	columnHelper.accessor("TAIL", {
// 		header: (info) => info.header.id,
// 		cell: (info) => info.getValue(),
// 		footer: (info) => info.column.id,
// 	}),
// 	columnHelper.accessor("AIRCRAFT", {
// 		header: (info) => info.header.id,
// 		cell: (info) => info.getValue(),
// 		footer: (info) => info.column.id,
// 	}),
// 	columnHelper.accessor("NAV CHARGES", {
// 		header: (info) => info.header.id,
// 		cell: (info) => info.getValue(),
// 		footer: (info) => info.column.id,
// 	}),
// 	columnHelper.accessor("LAND CHARGES", {
// 		header: (info) => info.header.id,
// 		cell: (info) => info.getValue(),
// 		footer: (info) => info.column.id,
// 	}),
// 	columnHelper.accessor("BILLED", {
// 		header: (info) => info.header.id,
// 		cell: (info) => {
// 			return info.getValue() === "CHECK" ? <Check /> : <Cross />;
// 		},
// 		footer: (info) => info.column.id,
// 	}),
// ];
