// React icons
import { IoMdWarning } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";

import { alertStatus } from "./status";

function FlashModal({ status, message = "Alert message", setShowFlashModal }) {
	const alertColor = alertStatus[status] || "neutral";

	return (
		<div
			className={`flex justify-between rounded-md p-3 lg:p-4 filter shadow-2xl border-b-4 h-11 lg:h-14 border-[${alertColor}]-500 fixed bg-white bottom-8 right-8`}
		>
			<div className="flex items-center">
				{/* Icon */}
				<span className={`mr-4 text-${alertColor}-500 lg:text-xl`}>
					{status === "success" ? (
						<AiFillCheckCircle />
					) : status === "warning" ? (
						<RiErrorWarningFill />
					) : (
						<IoMdWarning />
					)}
				</span>

				<p className="text-gray-600 mr-14 lg:mr-20 text-sm lg:text-base">
					{message}
				</p>
			</div>
			<MdClose
				className="text-gray-400 transform translate-y-1 cursor-pointer hover:text-gray-600"
				onClick={() => setShowFlashModal(false)}
			/>
		</div>
	);
}

export default FlashModal;
