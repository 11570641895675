function Button({
	label,
	type = "default",
	iconRight,
	iconLeft,
	onClick,
	className,
	disabled,
}) {
	let typeStyle;

	switch (type) {
		case "primary":
			typeStyle = "bg-primary-600 text-primary-25 hover:bg-primary-500";
			break;
		case "ghost":
			typeStyle =
				"border-2 border-primary-600 text-primary-600 hover:bg-primary-600 active:bg-primary-700 hover:text-primary-25";
			break;
		case "borderless":
			typeStyle = "text-primary-600 hover:underline";
			break;
		case "danger":
			typeStyle = "text-white bg-red-600 hover:bg-red-500 active:bg-red-700";
			break;
		case "default":
			typeStyle = "text-primary-800 hover:underline border";
			break;
		default:
			typeStyle = "text-primary-800 hover:underline border";
	}

	return (
		<button
			className={`${typeStyle} ${className} py-2 lg:py-3 px-4 lg:px-6 text-sm lg:text-base capitalize rounded-md active:outline-none font-body w-full flex-center whitespace-nowrap transition ease-out duration-500 disabled:opacity-40 disabled:cursor-not-allowed`}
			onClick={onClick}
			disabled={disabled}
		>
			{iconLeft && <span className="mr-2">{iconLeft}</span>}
			{label}
			{iconRight && <span className="ml-2"> {iconRight} </span>}
		</button>
	);
}

export default Button;
