import { useState } from "react";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { auth } from "../utils/auth";

function PrivateRoutes() {
	const [user, setUser] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (auth?.currentUser) {
			setUser(auth.currentUser);
		} else {
			setUser(null);
			navigate("/");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth]);

	return user && <Outlet />;
}

export default PrivateRoutes;
