import axios from "axios";

export const getAircraftRegistration = async (icao) => {
	const options = {
		method: "GET",
		url: `https://aerodatabox.p.rapidapi.com/aircrafts/icao24/${icao}`,
		headers: {
			"X-RapidAPI-Key": "c3f425ce0emsh915bbb3a6c5f7bfp1295d2jsnfc0d02d255c8",
			"X-RapidAPI-Host": "aerodatabox.p.rapidapi.com",
		},
	};

	try {
		const request = await axios.request(options);
		const reg = request.data.reg;
		return reg;
	} catch (error) {
		console.error(error);
		throw new Error("Registration number not available");
	}
};

export const getAirCraftImage = async (icao) => {
	const options = {
		method: "GET",
		url: `https://aerodatabox.p.rapidapi.com/aircrafts/icao24/${icao}`,
		params: { withImage: "true" },
		headers: {
			"X-RapidAPI-Key": "c3f425ce0emsh915bbb3a6c5f7bfp1295d2jsnfc0d02d255c8",
			"X-RapidAPI-Host": "aerodatabox.p.rapidapi.com",
		},
	};

	try {
		const request = await axios.request(options);
		console.log(request);
		const aircraftImg = request.data;
		return aircraftImg.image?.url;
	} catch (error) {
		console.error(error);
		throw new Error("Aircraft image not available");
	}
};
