import PrivateNavBar from "../PrivateNavBar/PrivateNavBar";
import TopBar from "../TopBar/TopBar";
import Footer from "../Footer/Footer";

function Layout({
	section,
	title,
	description,
	children,
	contentMargin = true,
	displayTopBar = true,
}) {
	return (
		<div id="app" className="h-screen w-screen flex">
			<PrivateNavBar activeSection={section} />
			<div id="main" className="flex flex-1 overflow-hidden w-full">
				<div className="flex-1 flex flex-col  overflow-x-hidden">
					{" "}
					{/* overflow-y-scroll */}
					{displayTopBar && <TopBar title={title} description={description} />}
					<div id="content" className={`w-full flex-grow ${contentMargin ? "p-5 lg:p-10" : null}`}>
						{children}
					</div>
					<Footer />
				</div>
			</div>
		</div>
	);
}

export default Layout;
