function Greet(userName) {
	let dayMoment;

	const hour = new Date().getHours();

	if (hour > 6 && hour < 12) dayMoment = "morning";
	else if (hour >= 12 && hour < 18) dayMoment = "afternoon";
	else if (hour >= 18 && hour < 20) dayMoment = "evening";
	else dayMoment = "night";

	return `Good ${dayMoment}, ${userName}`;
}

export default Greet;
