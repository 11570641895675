import { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { HiOutlineSupport } from "react-icons/hi";

import { logOut } from "../../utils/auth.js";

import { userDataContext } from "../../context/UserData/UserData.jsx";

import Avatar from "../Avatar/Avatar";
import NavBarButton from "../NavBarButton/NavBarButton";
import FlashModal from "../FlashModal/FlashModal";

import sections from "../../router/sections";

import acisLogo from "../../assets/acis.png";

function PrivateNavBar({ activeSection }) {
	const [errorMsg, setErrorMsg] = useState("");
	const [showFlashModal, setShowFlashModal] = useState(false);
	const navigate = useNavigate();
	const {
		userData: { email },
	} = useContext(userDataContext);

	const NavbarButtons = sections.map((section) => {
		return (
			<div className="w-full flex-1" key={section}>
				<NavBarButton icon={section} active={section === activeSection} />
			</div>
		);
	});

	const handleLogout = async () => {
		const isLoggedOut = await logOut();
		if (isLoggedOut === undefined) {
			navigate("/");
		} else {
			setErrorMsg("Not possible to logout");
		}
	};

	const getInitials = () => {
		const initials = email.slice(0, 2);
		return initials.toUpperCase();
	};

	getInitials();

	return (
		<div
			id="navbar"
			className="bg-primary-700 h-screen w-16 lg:w-20 overflow-y-hidden flex flex-col items-center"
		>
			<NavLink to="/planeslive" id="navbarLogo" className="flex-center mb-2 p-1 lg:p-2 flex-1">
				<img src={acisLogo} alt="Acis Logo" className="w-2/3 object-contain" />
			</NavLink>

			{NavbarButtons}

			<NavLink
				to="/contact"
				className="focus:outline-none	w-full flex-center text-3xl lg:text-4xl text-white text-center opacity-50 hover:opacity-100 flex-1"
			>
				<HiOutlineSupport />
			</NavLink>

			<div id="spacer" className="flex-1"></div>

			<div className="w-full flex-center p-2 flex-grow-0">
				<Avatar handleClick={handleLogout} initials={getInitials() ?? "AT"} />
			</div>
			{showFlashModal && (
				<FlashModal status={"error"} message={errorMsg} setShowFlashModal={setShowFlashModal} />
			)}
		</div>
	);
}

export default PrivateNavBar;
