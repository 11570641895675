function SectionTab({ label = "first section", section, currentSection, handleClick }) {
	const active = section === currentSection;

	const style = `cursor-pointer font-display font-bold uppercase text-center text-lg lg:text-xl pb-2 px-6 lg:px-10 flex border-b-4
	  ${active ? "text-primary-700 border-primary-700" : "text-gray-500 border-transparent"}`;

	return (
		<h2
			className={style}
			onClick={() => {
				handleClick(null, section);
			}}
		>
			{label}
		</h2>
	);
}

export default SectionTab;
