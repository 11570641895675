import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useState } from "react";
import Map from "react-map-gl";

import MapPlaneMarker from "../MapPlaneMarker/MapPlaneMarker.jsx";
import MapPlanePopup from "../MapPlanePopup/MapPlanePopup.jsx";
import TableTemplate from "../TableTemplate/TableTemplate.jsx";

function DisplayResults({ columns, section, data, wrapContent }) {
	const [popupInfo, setPopupInfo] = useState(null);
	const table = useReactTable({
		data: data ?? [],
		columns,
		getCoreRowModel: getCoreRowModel(),
	});

	if (section === "map") {
		const defaultPosition = {
			latitude: 41.38607310577369,
			longitude: 2.1788423889462885,
		};

		return (
			<div className="w-full">
				<Map
					initialViewState={{
						longitude: data[0]?.longitude ?? defaultPosition.longitude,
						latitude: data[0]?.latitude ?? defaultPosition.latitude,
						zoom: 7,
					}}
					style={{ height: 800 }}
					mapStyle="mapbox://styles/mapbox/dark-v10"
					mapboxAccessToken={
						"pk.eyJ1IjoiY3ZlbGlsbGFmIiwiYSI6ImNsOTJtMW0zdzEyc3gzdXFtdDkyeTVrdHAifQ.5vLrKys46D6jpBvN7PLVrg"
					}
				>
					{data &&
						data?.map((plane) => (
							<MapPlaneMarker key={plane.icao} plane={plane} setPopupInfo={setPopupInfo} />
						))}
					{popupInfo && (
						<MapPlanePopup popupInfo={popupInfo} setPopupInfo={setPopupInfo} showAside={false} />
					)}
				</Map>
			</div>
		);
	}

	if (section === "table") {
		return <TableTemplate table={table} />;
	}

	if (section === "JSON") {
		const maxElementsWhenWrapped = 2;

		return (
			<>
				<div className="ml-10">
					{wrapContent &&
						data.slice(0, maxElementsWhenWrapped).map((result) => {
							return (
								<pre key={result?.icao} className="mb-4">
									{JSON.stringify(result, undefined, 2)}
								</pre>
							);
						})}

					{!wrapContent &&
						data.map((result) => {
							return (
								<pre key={result?.icao} className="mb-4">
									{JSON.stringify(result, undefined, 2)}
								</pre>
							);
						})}
				</div>
				<div className="flex justify-center">
					<a
						href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`}
						download="queryresults.json"
						className="bg-primary-600 text-primary-25 hover:bg-primary-500 py-2 lg:py-3 px-4 lg:px-6 text-sm lg:text-base rounded-md active:outline-none font-body w-full flex-center whitespace-nowrap transition ease-out duration-500 disabled:opacity-40 disabled:cursor-not-allowed" // TODO: REFACTOR, STYLING AS BUTTON COMPONENT BUT NOT BUTTON
					>
						Download query results as JSON file
					</a>
				</div>
			</>
		);
	}

	return <></>;
}

export default DisplayResults;
