// React icons
import { useRef } from "react";
import { IoMdWarning } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";

import capitalizeString from "../../utils/capitalizeString";

function InputGroup({
	label = "label",
	placeholder = "placeholder",
	hint,
	status,
	message = "Success message",
	capitalize = false,
	inputType = "text",
	required = false,
	textArea = false,
	defaultValue = "",
	updateField = () =>
		console.error("Unable to execute the desired functionality"),
}) {
	const inputRef = useRef();

	const placeholderCapitalized = capitalizeString(placeholder);

	let statusColor;

	switch (status) {
		case "error":
			statusColor = "red";
			break;
		case "warning":
			statusColor = "yellow";
			break;
		case "success":
			statusColor = "green";
			break;
		default:
			statusColor = "gray";
			break;
	}

	return (
		<div className="w-full mb-3">
			<div className="flex text-xs justify-between">
				{label && <p className="capitalize">{label}</p>}
				{hint && <p className="text-gray-400 capitalize">{hint}</p>}
			</div>

			{/* Input box */}

			{textArea ? (
				<textarea
					ref={inputRef}
					required={required}
					className={`border border-${statusColor}-500 rounded-md w-full my-2 p-2 focus:outline-none focus:ring-2 focus:ring-${statusColor}-700 focus:ring-opacity-50 border-${statusColor}-200 `}
					placeholder={capitalize ? placeholderCapitalized : placeholder}
					onChange={() => {
						updateField(label, inputRef.current.value);
					}}
				></textarea>
			) : defaultValue ? (
				<input
					ref={inputRef}
					type={inputType}
					required={required}
					value={defaultValue}
					className={`border border-${statusColor}-500 rounded-md w-full my-2 p-2 focus:outline-none focus:ring-2 focus:ring-${statusColor}-700 focus:ring-opacity-50 border-${statusColor}-200 `}
					placeholder={capitalize ? placeholderCapitalized : placeholder}
					onChange={() => {
						updateField(label, inputRef.current.value);
					}}
				></input>
			) : (
				<input
					ref={inputRef}
					type={inputType}
					required={required}
					className={`border border-${statusColor}-500 rounded-md w-full my-2 p-2 focus:outline-none focus:ring-2 focus:ring-${statusColor}-700 focus:ring-opacity-50 border-${statusColor}-200 `}
					placeholder={capitalize ? placeholderCapitalized : placeholder}
					onChange={() => {
						updateField(label, inputRef.current.value);
					}}
				></input>
			)}

			{/* Status message */}
			{status && (
				<div className="flex">
					{/* Icon */}
					<span className={`flex mr-1 text-${statusColor}-500 `}>
						{status === "success" ? <IoMdWarning /> : <RiErrorWarningFill />}
					</span>

					{/* Text */}
					<p className="text-xs text-gray-400">{message}</p>
				</div>
			)}
		</div>
	);
}

export default InputGroup;
